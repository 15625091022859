<template>
  <div>
    <div class="table-container">
      <h3 class="table-title">Companies</h3>
      <div class="table-toolbar">
        <el-input v-model="search" prefix-icon="el-icon-search" @input="handleSearch" size="small"
          style="width: 300px; flex: 1 1 0%; margin-right: 0.5rem;" placeholder="Search By Company Name..." />
        <el-button style="flex: 0 1 0%;" size="small" icon="el-icon-refresh" @click="refreshTable()" type="warning">
          Refresh Table
        </el-button>
        <el-button type="primary" @click="openCreateCompanyModal" size="small">New</el-button>
      </div>
    </div>

    <el-table :data="companies" :current-page="currentPage" :total="totalItems" :table-props="tableProps"
      :show-action-bar="false" :filters="[searchDef]" style="width: 100%" class="company-table" v-loading="loading">
      <el-row class="table-header" slot="tool">
        <el-col class="table-header-name">
          <span>Companies</span>
        </el-col>
        <el-col class="table-header-container">
          <div style="flex: 1">
            <el-input v-model="searchDef.value" placeholder="Search By Name..." size="small">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div style="margin-left: 1rem; flex: none">
            <el-button type="primary" @click="openCreateCompanyModal" size="small">
              New
            </el-button>
          </div>
        </el-col>
      </el-row>

      <el-table-column label="" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.active">
            <el-tooltip effect="dark" content="Company suspended." placement="bottom">
              <font-awesome-icon icon="pause-circle" fixed-width></font-awesome-icon>
            </el-tooltip>
          </span>
          <span v-else>
            <el-tooltip effect="dark" content="Company active." placement="bottom">
              <font-awesome-icon icon="check-circle" fixed-width></font-awesome-icon>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="Name" sortable="custom" min-width="150"
        show-overflow-tooltip></el-table-column>

      <el-table-column prop="user_count" label="Users" sortable="custom" width="80"></el-table-column>

      <el-table-column prop="rep_count" label="Reps" sortable="custom" width="80"></el-table-column>

      <el-table-column prop="created_at" label="Created" sortable="custom" width="200">
        <template slot-scope="scope">
          <span>{{ dateTimeFormat(scope.row.created_at, "MM/DD/YYYY") }} by
            {{ scope.row.created_by.name }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="updated_at" label="Updated" sortable="custom" width="180">
        <template slot-scope="scope">
          <span>{{
          dateTimeFormat(scope.row.updated_at, "MM/DD/YYYY [at] hh:mm a")
        }}</span>
        </template>
      </el-table-column>

      <el-table-column label="" width="100" align="center">
        <template slot-scope="scope">
          <router-link :to="{ name: 'company', params: { company: scope.row.slug } }" style="margin-right: 0.5rem">
            View
          </router-link>
          <el-dropdown trigger="click" @command="companyDropdown($event, scope.row)">
            <span class="el-dropdown-link">
              <el-button type="text" style="padding: 0" size="small">
                <font-awesome-icon icon="ellipsis-v" fixed-width></font-awesome-icon>
              </el-button>
            </span>
            <el-dropdown-menu class="dropdown-left-adjust" slot="dropdown">
              <el-dropdown-item command="edit">
                <font-awesome-icon icon="pencil-alt" fixed-width></font-awesome-icon>
                Edit
              </el-dropdown-item>
              <el-dropdown-item command="suspend" v-if="scope.row.active">
                <font-awesome-icon icon="lock" fixed-width></font-awesome-icon>
                Suspend
              </el-dropdown-item>
              <el-dropdown-item command="unsuspend" v-else-if="!scope.row.active">
                <font-awesome-icon icon="lock-open" fixed-width></font-awesome-icon>
                Unsuspend
              </el-dropdown-item>
              <el-dropdown-item command="delete">
                <font-awesome-icon icon="trash-alt" fixed-width></font-awesome-icon>
                Delete
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

  <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="20" class="pagination"
    layout="prev, pager, next" :total="totalItems">
  </el-pagination>
  </div>
</template>


<style lang="scss">
.company-table {
  td.name {
    max-width: 150px;
  }
}

.pagination {
  text-align: center;
}

.table-title {
  display: flex;
  border-bottom: 2px solid #d4a100;
  padding-right: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #303133;
}

.table-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.table-toolbar {
  display: flex;
  justify-content: flex-end;
  width: 500px;
  max-width: 100%;
  background: #fafafa;
  padding: .25rem .5rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
}
</style>


<script>
import CompanyCreateForm from "@/components/Forms/CompanyCreateForm"
import CompanyEditForm from "@/components/Forms/CompanyEditForm"
import { debounce } from 'lodash'
import DateTime from "@/mixins/DateTime"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CompanyTable",

  mixins: [DateTime],

  data() {
    return {
      searchDef: {
        value: "",
        prop: "name",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "name",
          order: "ascending",
        },
      },
      loading: false,
      currentPage: 1,
      search: '',
    }
  },
  computed: {
    ...mapGetters('companies', ['list', 'totalItems']),
    companies() {
      return this.list;
    }
  },
  created() {
    this.fetchData(this.currentPage);
  },

  methods: {
    ...mapActions({
      refreshCompanies: "companies/refresh",
      updateCompany: "companies/update",
      removeCompany: "companies/remove",
    }),

    fetchData(page, search = this.search) {
      this.refreshCompanies({ page, query: search });
    },
    refreshTable() {
      this.search = '';
      this.currentPage = 1;
      this.fetchData(this.currentPage, this.search);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData(page);
    },
    handleSearch: debounce(function () {
      this.currentPage = 1; // Reset to first page when searching
      this.fetchData(this.currentPage);
    }, 500),
    openCreateCompanyModal() {
      this.$modal.show(
        CompanyCreateForm,
        {},
        {
          name: "company-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    companyDropdown(command, company) {
      if (command === "edit") {
        this.$modal.show(
          CompanyEditForm,
          {
            existing: company,
          },
          {
            name: "company-edit-form",
            adaptive: true,
            height: "auto",
            scrollable: true,
          }
        )
      } else if (command === "suspend" || command === "unsuspend") {
        this.$confirm(
          command.charAt(0).toUpperCase() +
          command.slice(1) +
          " " +
          company.name +
          "?",
          "",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: command === "suspend" ? "warning" : null,
            callback: (action) => {
              if (action === "confirm") {
                this.axios
                  .post("companies/suspend/" + company.slug, {})
                  .then((response) => {
                    this.updateCompany(response.data.data)
                    this.$message.success(response.data.message)
                  })
                  .catch((resp) => {
                    this.$root.globalRequestError(resp)
                  })
              }
            },
          }
        ).catch(() => { })
      } else if (command === "delete") {
        this.$confirm(
          "Delete " + company.name + "? This action cannot be undone.",
          "",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "error",
            callback: (action) => {
              if (action === "confirm") {
                this.axios
                  .post("companies/delete/" + company.slug, {})
                  .then((response) => {
                    this.removeCompany(response.data.data)
                    this.$message.success(response.data.message)
                  })
                  .catch((resp) => {
                    this.$root.globalRequestError(resp)
                  })
              }
            },
          }
        ).catch(() => { })
      }
    },
  },

  watch: {
    data() {
      this.loading = false
    },
  },
}
</script>
