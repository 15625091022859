<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Create A Company</span>
      <el-button type="text" @click="$modal.hide('company-create-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="company.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('users') ? form.errors.first('users') : null"
        label="Users"
      >
        <modal-select>
          <el-select
            v-model="company.users"
            :style="{ width: '100%' }"
            multiple
            filterable
            remote
            reserve-keyword
            :remote-method="searchUsers"
            :loading="loading"
            clearable
            placeholder="Assign Users..."
            @focus="form.errors.clear('users')"
          >
            <el-option
              v-for="item in clients"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            ></el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('reps') ? form.errors.first('reps') : null"
        label="Reps"
      >
        <modal-select>
          <el-select
            v-model="company.reps"
            :style="{ width: '100%' }"
            multiple
            filterable
            remote
            reserve-keyword
            :remote-method="searchUsers"
            :loading="loading"
            clearable
            placeholder="Assign Representatives..."
            @focus="form.errors.clear('reps')"
          >
            <el-option
              v-for="item in reps"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            >
            </el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-button-group :style="{ width: '100%' }">
        <el-button
          plain
          medium
          native-type="button"
          v-loading="loading"
          :style="{ width: '50%' }"
          @click="create(false)"
        >
          Create & Close
        </el-button>

        <el-button
          type="primary"
          plain
          medium
          native-type="button"
          v-loading="loading"
          :style="{ width: '50%' }"
          @click="create(true)"
        >
          Create & Continue
        </el-button>
      </el-button-group>
    </el-form>
  </el-card>
</template>


<script>
import CompanyEditForm from "@/components/Forms/CompanyEditForm"

import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CompanyCreateForm",

  mixins: [],

  data() {
    return {
      company: {
        name: "",
        users: [],
        reps: [],
      },
      form: new Form(),
      loading: false,
      filteredUsers: [],
    }
  },
  computed: {
    ...mapGetters({
      users: "users/list",
    }),
    clients() {
      return this.$_.filter(this.users, function (o) {
        return o.role === "approval-client" || o.role === "client"
      })
    },
    reps() {
      return this.$_.filter(this.users, function (o) {
        return o.role === "super" || o.role === "admin" || o.role === "employee"
      })
    },
  },

  methods: {
    ...mapActions({
      refreshUsers: "users/refresh",
      addCompany: "companies/add",
      updateAuth: "auth/update",
    }),
    create(edit) {
      this.loading = true
      this.axios
        .post("companies/create", this.company)
        .then((response) => {
          this.addCompany(response.data.data)
          this.updateAuth()
          this.$modal.hide("company-create-form")
          if (edit) {
            this.$modal.show(
              CompanyEditForm,
              {
                existing: response.data.data,
              },
              {
                name: "company-edit-form",
                adaptive: true,
                height: "auto",
                scrollable: true,
              }
            )
          } else {
            this.$message.success(response.data.message)
          }
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    searchUsers(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.refreshUsers({ query: query }).then((users) => {
            this.filteredUsers = users;
            this.loading = false;
          });
        }, 200);
      } else {
        this.filteredUsers = [];
      }
    },
  },

  created() {
    this.refreshUsers({page:1})
  },
}
</script>
