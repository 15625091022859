<template>
  <el-card>
    <company-table></company-table>
  </el-card>
</template>

<script>
import CompanyTable from "@/components/Tables/CompanyTable"

export default {
  name: "CompanyAdmin",

  components: {
    "company-table": CompanyTable,
  },
}
</script>
